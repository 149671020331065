import React from "react"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import "./index.scss"
import { graphql, useStaticQuery } from "gatsby"
import "./our-story.scss"
import Img from "gatsby-image"

const OurStory = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOurStory {
        mainImage {
          file {
            url
          }
        }
        pageTitle
      }
    }
  `)

  return (
    <div>
      <Header />
      <Navbar />
      <h1 className="page-title">{data.contentfulOurStory.pageTitle}</h1>
      <div className="page-imageContainer">
        <img
          className="ourStory-images"
          src={data.contentfulOurStory.mainImage.file.url}
        />
      </div>
    </div>
  )
}

export default OurStory
